import {
	type DomainAccount,
	type UnsyncedAccount,
	buildPlanRouteUrl,
} from "@exopengithub/planning-api-shared";
import { emptyPlanningApi } from "./emptyPlanningApi.js";
import type { EntityState } from "@reduxjs/toolkit";
import { accountsAdapter } from "../utils/accountsAdapter.js";

const assetApi = emptyPlanningApi.injectEndpoints({
	endpoints: (builder) => ({
		getAccounts: builder.query<
			EntityState<DomainAccount>,
			{ planId: string; companyDomainId: string }
		>({
			query: ({ planId, companyDomainId }) =>
				`/v1/${companyDomainId}/plans/${planId}/accounts`,
			transformResponse: (data: DomainAccount[]) => {
				return accountsAdapter.addMany(accountsAdapter.getInitialState(), data);
			},
			providesTags: (result, _error, arg) =>
				result
					? [
							...result.ids.map((id) => ({ type: "Account" as const, id })),
							"Account",
						]
					: ["Account", { type: "Plan", id: arg.planId }],
		}),

		getPlanningAccountNumbers: builder.query<
			DomainAccount[],
			{ planId: string; companyDomainId: string }
		>({
			query: ({ planId, companyDomainId }) =>
				`/v1/${companyDomainId}/plans/${planId}/planningaccountnumbers`,
			providesTags: ["PlanningAccountNumber"],
		}),

		getUnsyncedAccounts: builder.query<
			UnsyncedAccount[],
			{ planId: string; companyDomainId: string; legalEntityId: string }
		>({
			query: ({ planId, companyDomainId, legalEntityId }) =>
				buildPlanRouteUrl("/unsynced-accounts", {
					companyDomainId,
					legalEntityId,
					planId,
				}),
			providesTags: ["UnsyncedAccount"],
		}),

		deleteAccount: builder.mutation<
			void,
			{
				planId: string;
				legalEntityId: string;
				companyDomainId: string;
				accountId: string;
			}
		>({
			query({ planId, legalEntityId, companyDomainId, accountId }) {
				return {
					method: "DELETE",
					url: buildPlanRouteUrl(`/accounts/${accountId}`, {
						companyDomainId,
						legalEntityId,
						planId,
					}),
				};
			},
			invalidatesTags(_, _2, args) {
				return ["UnsyncedAccount", { type: "Account", id: args.accountId }];
			},
		}),
	}),
	overrideExisting: false,
});

export const {
	useGetUnsyncedAccountsQuery,
	useGetAccountsQuery,
	useGetPlanningAccountNumbersQuery,
	useDeleteAccountMutation,
} = assetApi;
