import { Route, Routes } from "react-router-dom";
import { Tabs } from "../../common/components/atoms/tabs/Tabs";
import { BreadcrumbsLayout } from "../../common/components/layout/BreadcrumbsLayout";
import { useDocumentTitle } from "../../common/utils/hooks/useDocumentTitle";
import { FakePumps } from "./FakePumps";
import { LegalEntities } from "./LegalEntities";
import { MartManagement } from "./MartManagement";
import { Pumps } from "./Pumps";
import { SupportTools } from "./SupportTools";
import { PlanningAdminCP } from "../planning/PlanningAdminCP";
import { TabLink } from "../../common/components/atoms/tabs/TabLink.js";
import { DLMTools } from "./DLMTools";
import { TemplateApps } from "./TemplateApps.js";
import { IconBug } from "@tabler/icons-react";
import { AzureResourceTools } from "./AzureResourceTools.js";

const tabs = [
	{ path: ".", value: "Allmänt" },
	{ path: "pumps", value: "Pumpar" },
	{ path: "legal-entities", value: "Legala enheter" },
	{ path: "fake-pumps", value: "Fejkpumpar" },
	{ path: "ui-components", value: "UI-komponenter" },
	{ path: "planning", value: "Planning" },
	{ path: "azure-resources", value: "Azure Resources" },
	{ path: "marts", value: "Mart" },
	{ path: "dlm", value: "DLM" },
	{ path: "template-apps", value: "Template Apps" },
];

export const DevToolsPage = () => {
	const pageName = "Utvecklingsverktyg";
	useDocumentTitle(pageName);

	return (
		<BreadcrumbsLayout breadcrumbs={[{ icon: <IconBug />, name: pageName }]}>
			<Tabs className="mb-4">
				{tabs.map((tab) => {
					return (
						<TabLink key={tab.path} to={tab.path} end>
							{tab.value}
						</TabLink>
					);
				})}
			</Tabs>
			<Routes>
				<Route index element={<SupportTools />} />
				<Route path="pumps" element={<Pumps />} />
				<Route path="legal-entities" element={<LegalEntities />} />
				<Route path="fake-pumps" element={<FakePumps />} />
				<Route path="marts" element={<MartManagement />} />
				<Route path="planning" element={<PlanningAdminCP />} />
				<Route path="azure-resources" element={<AzureResourceTools />} />
				<Route path="dlm" element={<DLMTools />} />
				<Route path="template-apps" element={<TemplateApps />} />
			</Routes>
		</BreadcrumbsLayout>
	);
};
