import { LoadingState } from "components/loadingState/LoadingState";
import { MissingDataState } from "components/missingDataState/MissingDataState";
import { useCurrentCompanyDomainId } from "src/common/company-domain/useCurrentCompanyDomain";
import { useGetCompanyDomainStatusQuery } from "src/common/redux/api/exopenApi";
import {
	PlanningTable,
	PlanningTableContainer,
} from "../planning/components/PlanningTable";
import { ConfigurationStatus } from "src/common/redux/api/exopenApiTypes";
import { ProvisionResourceButton } from "./components/azureResources/ProvisionResourceButton";
import { AzureResources } from "./components/azureResources/azureResources";
import { DeleteResourceButton } from "./components/azureResources/DeleteResourceButton";

export const AzureResourceTools = () => {
	const { companyDomainId } = useCurrentCompanyDomainId();

	const {
		data: companyDomainData,
		isLoading,
		refetch,
	} = useGetCompanyDomainStatusQuery({ companyDomainId });

	if (isLoading) {
		return <LoadingState />;
	}

	if (!companyDomainData) {
		return <MissingDataState />;
	}

	const availableResources = [
		{
			resourceName:
				companyDomainData.company.databaseStatus === ConfigurationStatus.Success
					? `${AzureResources.Database}: ${companyDomainData.company.databaseName}`
					: AzureResources.Database,
			status: companyDomainData.company.databaseStatus,
			provisionBtn: (
				<ProvisionResourceButton
					resource={AzureResources.Database}
					companyDomainId={companyDomainId}
					disabled={
						companyDomainData.company.databaseStatus ===
						ConfigurationStatus.Success
					}
					refetch={refetch}
				/>
			),
			deleteBtn: null,
		},
		{
			resourceName: AzureResources.StorageAccount,
			status: companyDomainData.company.storageAccountStatus,
			provisionBtn: (
				<ProvisionResourceButton
					resource={AzureResources.StorageAccount}
					companyDomainId={companyDomainId}
					disabled={
						companyDomainData.company.storageAccountStatus ===
						ConfigurationStatus.Success
					}
					refetch={refetch}
				/>
			),
			deleteBtn: (
				<DeleteResourceButton
					resource={AzureResources.StorageAccount}
					companyDomainId={companyDomainId}
					disabled={
						companyDomainData.company.storageAccountStatus !==
						ConfigurationStatus.Success
					}
					refetch={refetch}
				/>
			),
		},
		{
			resourceName: AzureResources.DataFactory,
			status: companyDomainData.company.dataFactoryStatus,
			provisionBtn: (
				<ProvisionResourceButton
					resource={AzureResources.DataFactory}
					companyDomainId={companyDomainId}
					disabled={
						companyDomainData.company.dataFactoryStatus ===
						ConfigurationStatus.Success
					}
					refetch={refetch}
				/>
			),
			deleteBtn: (
				<DeleteResourceButton
					resource={AzureResources.DataFactory}
					companyDomainId={companyDomainId}
					disabled={
						companyDomainData.company.dataFactoryStatus !==
						ConfigurationStatus.Success
					}
					refetch={refetch}
				/>
			),
		},
		{
			resourceName: AzureResources.DatabaseBackup,
			status: companyDomainData.company.databaseBackupStatus,
			provisionBtn: (
				<ProvisionResourceButton
					resource={AzureResources.DatabaseBackup}
					companyDomainId={companyDomainId}
					disabled={
						companyDomainData.company.databaseBackupStatus ===
						ConfigurationStatus.Success
					}
					refetch={refetch}
				/>
			),
			deleteBtn: (
				<DeleteResourceButton
					resource={AzureResources.DatabaseBackup}
					companyDomainId={companyDomainId}
					disabled={
						companyDomainData.company.databaseBackupStatus !==
						ConfigurationStatus.Success
					}
					refetch={refetch}
				/>
			),
		},
	];

	return (
		<PlanningTableContainer>
			<PlanningTable>
				<thead>
					<tr>
						<th>Resource</th>
						<th>Resource Status</th>
						<th />
						<th />
						<th className="w-0" />
					</tr>
				</thead>
				<tbody>
					{availableResources.map((row, index) => {
						return (
							<tr key={index}>
								<td>{row.resourceName}</td>
								<td>{row.status}</td>
								<td>{row.provisionBtn}</td>
								<td>{row.deleteBtn}</td>
							</tr>
						);
					})}
				</tbody>
			</PlanningTable>
		</PlanningTableContainer>
	);
};
