import { clsx } from "clsx";
import { forwardRef, type TextareaHTMLAttributes } from "react";
import { COMMON_BORDERLESS_CLASS, COMMON_INPUT_CLASS } from "./inputUtils";

export interface TextareaProps
	extends TextareaHTMLAttributes<HTMLTextAreaElement> {
	resizable?: boolean;
	borderless?: boolean;
}

export const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
	function Textarea(
		{
			className,
			["aria-invalid"]: ariaInvalid,
			resizable = true,
			borderless = false,
			...props
		},
		ref,
	) {
		return (
			<textarea
				aria-invalid={ariaInvalid}
				className={clsx(
					borderless ? COMMON_BORDERLESS_CLASS : COMMON_INPUT_CLASS,
					"w-full px-3 py-2 text-sm leading-5 text-gray-900 disabled:cursor-not-allowed disabled:text-gray-500",
					resizable ? "resize-y" : "resize-none",
					className,
				)}
				ref={ref}
				{...props}
			/>
		);
	},
);
