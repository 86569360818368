import { Button } from "components/button/Button.tsx";
import {
	showErrorNotification,
	showSuccessNotification,
} from "components/notifications/events.tsx";
import { Select } from "components/select/Select";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import type { CompanyDomain } from "src/common/company-domain/types.ts";
import type { ErLegalEntity } from "src/common/service/fusionHub/types.ts";
import type { LegalEntity } from "src/common/service/nexus/endpoints/companyDomain/types.ts";
import type { Integration as NexusIntegration } from "src/common/service/nexus/endpoints/integration/types.ts";
import {
	useAddIntegrationToLegalEntityMutation,
	useUpsertLegalEntityFromFusionhubIntegrationsMutation,
} from "src/common/service/nexus/nexusApi";
import { useCallback, useEffect } from "react";
import { BreadcrumbsLayout } from "src/common/components/layout/BreadcrumbsLayout.tsx";
import { IconSettings } from "@tabler/icons-react";
import {
	Card,
	CardHeader,
	CardItemsHeader,
} from "src/features/planning/components/Card.tsx";
import { Label } from "components/label/Label.tsx";
import { ValidateHint } from "src/features/planning/components/ValidateHint.tsx";
import { Navigate } from "react-router-dom";
import { usePersistedNavigate } from "src/common/persistentRouterFunctions.tsx";
import { useAppDispatch } from "../../../common/redux/store";
import { fusionHubApi } from "src/common/service/fusionHub/fusionHubApi";

type Props = {
	companyDomain: CompanyDomain;
	integrationId: string;
	existingIntegrations: NexusIntegration[];
	legalEntity: ErLegalEntity;
	legalEntities: LegalEntity[];
	autoResolve: boolean;
};

export const LegalEntityConnector = ({
	companyDomain,
	integrationId,
	legalEntity,
	legalEntities,
	existingIntegrations,
	autoResolve,
}: Props) => {
	const { t } = useTranslation();

	const navigate = usePersistedNavigate();

	const [addIntegration] = useAddIntegrationToLegalEntityMutation();
	const dispatch = useAppDispatch();

	const legalEntitiesWithoutIntegration = legalEntities.filter(
		(legalEntity) =>
			!existingIntegrations.some(
				(integration) => integration.legalEntityId === legalEntity.id,
			),
	);

	const addIntegrationToLegalEntity = useCallback(
		async (legalEntityId: string, triggerSuccessToast: boolean) => {
			const addIntegrationResult = await addIntegration({
				companyDomainId: companyDomain.id,
				legalEntityId,
				integrationId,
			});

			if ("error" in addIntegrationResult) {
				showErrorNotification({
					message: t("Failed to add integration to legal entity"),
				});
				return;
			}

			dispatch(fusionHubApi.util.invalidateTags(["Integrations"]));
			if (triggerSuccessToast) {
				showSuccessNotification({
					message: t("Created legal entity {{name}}", {
						name: legalEntity.name,
					}),
				});
			}
		},
		[
			addIntegration,
			companyDomain.id,
			integrationId,
			t,
			legalEntity.name,
			dispatch,
		],
	);

	const [createLegalEntity, { isLoading: isUpsertingLegalEntity }] =
		useUpsertLegalEntityFromFusionhubIntegrationsMutation();

	const createAndConnect = useCallback(
		async (triggerSuccessToast: boolean) => {
			navigate(`/${companyDomain.id}/administration/connectors`);

			const createLegalEntityResult = await createLegalEntity({
				companyDomainId: companyDomain.id,
				integrationIds: [Number(integrationId)],
			});

			if ("error" in createLegalEntityResult) {
				showErrorNotification({
					message: t("Failed to create legal entity"),
				});
				return;
			}

			if (triggerSuccessToast) {
				showSuccessNotification({
					message: t("Created legal entity {{name}}", {
						name: createLegalEntityResult.data.name,
					}),
				});
			}
		},
		[companyDomain.id, createLegalEntity, t, navigate, integrationId],
	);

	const existingLegalEntityForm = useForm({
		defaultValues: {
			legalEntityId: "",
		},
	});

	useEffect(() => {
		if (autoResolve) {
			createAndConnect(false);
		}
	}, [autoResolve, createAndConnect]);

	const integrationIsConnectedAlready = existingIntegrations.some(
		(integration) =>
			integration.source === "FusionHub" &&
			integration.sourceId === integrationId,
	);

	if (integrationIsConnectedAlready) {
		return <Navigate to={`/${companyDomain.id}/administration/connectors`} />;
	}

	return (
		<BreadcrumbsLayout
			breadcrumbs={[
				{
					icon: <IconSettings />,
					name: t('Finish setup of "{{legalEntityName}}"', {
						legalEntityName: legalEntity.name,
					}),
				},
			]}
		>
			<div className="flex flex-col gap-4">
				{legalEntitiesWithoutIntegration.length > 0 && (
					<Card>
						<CardHeader>
							<CardItemsHeader
								heading={t("Connect to existing legal entity")}
							/>
						</CardHeader>
						<form
							className="flex flex-col gap-2 p-4"
							onSubmit={existingLegalEntityForm.handleSubmit(
								async ({ legalEntityId }) => {
									await addIntegrationToLegalEntity(legalEntityId, true);
								},
							)}
						>
							<Controller
								control={existingLegalEntityForm.control}
								name="legalEntityId"
								rules={{
									required: t("This field is required"),
								}}
								render={({ field, fieldState }) => {
									return (
										<div className="mb-4">
											<Label>{t("Select legal entity")}</Label>
											<Select
												options={legalEntitiesWithoutIntegration.map(
													(legalEntity) => ({
														label: legalEntity.name,
														value: legalEntity.id,
													}),
												)}
												placeholder=""
												ariaLabel={t("Select legal entity")}
												{...field}
											/>
											{fieldState.error && (
												<ValidateHint error>
													{fieldState.error.message}
												</ValidateHint>
											)}
										</div>
									);
								}}
							/>
							<Button
								className="align-self-start"
								type="submit"
								isLoading={existingLegalEntityForm.formState.isSubmitting}
							>
								{t("Connect")}
							</Button>
						</form>
					</Card>
				)}
				<Card>
					<CardHeader>
						<CardItemsHeader heading={t("Create a new legal entity")} />
					</CardHeader>
					<Button
						className="align-self-start"
						onClick={async () => {
							await createAndConnect(true);
						}}
						isLoading={isUpsertingLegalEntity}
					>
						{t("Create")}
					</Button>
				</Card>
			</div>
		</BreadcrumbsLayout>
	);
};
