import type { PaginatedResponse, Builder } from "../../types.js";
import type { VoucherAggregate } from "./types.js";
import type { QueryReturnValue } from "@reduxjs/toolkit/dist/query/baseQueryTypes.js";
import type {
	FetchBaseQueryError,
	FetchBaseQueryMeta,
} from "@reduxjs/toolkit/dist/query/fetchBaseQuery.js";
import { loadAllPages } from "../../utils/loadAllPages.js";
import { formatYYYYMMDD } from "src/features/planning/utils.js";

export const buildVoucherEndpoints = (builder: Builder) => ({
	getAllVoucherAggregate: builder.query<
		VoucherAggregate[],
		{
			companyDomainId: string;
			legalEntityId: string;
			take?: number;
			skip?: number;
			from?: Date;
			to?: Date;
			granularity?: "day" | "month" | "year";
			dimensions?: (
				| "project"
				| "customer"
				| "supplier"
				| "product"
				| "cost-center"
			)[];
		}
	>({
		queryFn(
			{ companyDomainId, legalEntityId, from, to, granularity, dimensions },
			_api,
			_extraOptions,
			baseQuery,
		) {
			return loadAllPages(({ take, skip }) => {
				return baseQuery({
					url: `/v1/company-domain/${companyDomainId}/legal-entity/${legalEntityId}/voucher/aggregate`,
					params: {
						take,
						skip,
						from: from ? formatYYYYMMDD(from) : undefined,
						to: to ? formatYYYYMMDD(to) : undefined,
						granularity,
						dimensions: dimensions?.join(","),
					},
				}) as Promise<
					QueryReturnValue<
						PaginatedResponse<VoucherAggregate>,
						FetchBaseQueryError,
						FetchBaseQueryMeta
					>
				>;
			});
		},
	}),
});
