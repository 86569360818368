import { emptyFusionHubApi } from "./emptyFusionHubApi.ts";
import type {
	PaginatedParams,
	FusionhubIntegration,
	PaginatedResponse,
	ErLegalEntity,
	ErDocumentation,
	ErSyncStatus,
} from "./types.ts";

export type ErSyncStatusWithFusionHubIntegration = {
	status: ErSyncStatus | null;
	integration: FusionhubIntegration;
};

export const fusionHubApi = emptyFusionHubApi.injectEndpoints({
	endpoints: (builder) => ({
		getErDocumentation: builder.query<ErDocumentation, void>({
			query: () => "/er/documentation",
		}),
		getAllIntegrations: builder.query<
			PaginatedResponse<FusionhubIntegration>,
			{ companyDomainId: string } & PaginatedParams
		>({
			query: (params) => ({
				url: "/integrations",
				params,
			}),
			providesTags: () => [
				{
					type: "Integrations",
				},
			],
		}),
		getErIntegrations: builder.query<
			PaginatedResponse<FusionhubIntegration>,
			{ companyDomainId: string } & PaginatedParams
		>({
			query: (params) => ({
				url: "/er/integrations",
				params,
			}),
			providesTags: () => [
				{
					type: "Integrations",
				},
			],
		}),
		getErLegalEntity: builder.query<ErLegalEntity, { integrationId: number }>({
			query: ({ integrationId }) =>
				`/er/integrations/${integrationId}/legal-entity`,
		}),
		getErSyncStatus: builder.query<
			{
				syncs: ErSyncStatusWithFusionHubIntegration[];
			},
			{ integrationIds: number[] }
		>({
			query: ({ integrationIds }) => {
				return {
					url: `/er/integrations/sync-aggregated?${integrationIds.map((id) => `integrationId=${id}`).join("&")}`,
				};
			},
			providesTags: (_result, error, { integrationIds }) => {
				if (error) {
					return ["SyncStatus"];
				}
				return integrationIds.map((integrationId) => {
					return { type: "SyncStatus", id: integrationId };
				});
			},
		}),
		createDemoIntegration: builder.mutation<
			FusionhubIntegration,
			{ companyDomainId: string }
		>({
			query: ({ companyDomainId }) => ({
				url: "/integrations",
				params: { companyDomainId },
				method: "POST",
				body: { companyDomainId, type: "DEMO" },
			}),
			invalidatesTags: [{ type: "Integrations" }],
		}),
		triggerSync: builder.mutation<void, { integrationIds: number[] }>({
			query: ({ integrationIds }) => ({
				url: "/er/integrations/sync",
				method: "POST",
				body: { integrationIds },
			}),
			invalidatesTags: (_result, _error, { integrationIds }) => {
				return integrationIds.flatMap((integrationId) => [
					{ type: "SyncStatus", id: integrationId },
					{ type: "Integrations", id: integrationId },
				]);
			},
		}),
		removeIntegration: builder.mutation<void, { integrationId: number }>({
			query: ({ integrationId }) => ({
				url: `/integrations/${integrationId}`,
				method: "DELETE",
			}),
			invalidatesTags: (_result, _error, { integrationId }) => [
				{ type: "SyncStatus", id: integrationId },
				{ type: "Integrations" },
			],
		}),
	}),
});

export const {
	useGetAllIntegrationsQuery,
	useGetErIntegrationsQuery,
	useGetErLegalEntityQuery,
	useGetErDocumentationQuery,
	useGetErSyncStatusQuery,
	useTriggerSyncMutation,
	useRemoveIntegrationMutation,
	useCreateDemoIntegrationMutation,
	endpoints: { triggerSync },
} = fusionHubApi;
