import { IconTrash } from "@tabler/icons-react";
import { Button } from "components/button";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ConfirmDialog } from "components/dialog/ConfirmDialog";
import {
	useDeleteCompanyDomainDataFactoryMutation,
	useDeleteCompanyDomainStorageAccountMutation,
	useDisableCompanyDomainDatabaseBackupMutation,
} from "src/common/redux/api/exopenApi";
import {
	showErrorNotification,
	showSuccessNotification,
} from "components/notifications/events";
import { AzureResources } from "./azureResources";

interface Props {
	resource: AzureResources;
	companyDomainId: string;
	disabled: boolean;
	refetch: () => void;
}

export const DeleteResourceButton = ({
	resource,
	companyDomainId,
	disabled,
	refetch,
}: Props) => {
	const { t } = useTranslation();

	const [showDeleteResourceModal, setShowDeleteResourceModal] = useState(false);

	const [
		deleteCompanyDomainStorageAccount,
		{ isLoading: isDeletingStorageAccount },
	] = useDeleteCompanyDomainStorageAccountMutation();

	const [deleteCompanyDomainDataFactory, { isLoading: isDeletingDataFactory }] =
		useDeleteCompanyDomainDataFactoryMutation();

	const [disableCompanyDomainDatabaseBackup, { isLoading: isDisablingBackup }] =
		useDisableCompanyDomainDatabaseBackupMutation();

	const deleteQuery = () => {
		switch (resource) {
			case AzureResources.DataFactory:
				return deleteCompanyDomainDataFactory;
			case AzureResources.StorageAccount:
				return deleteCompanyDomainStorageAccount;
			case AzureResources.DatabaseBackup:
				return disableCompanyDomainDatabaseBackup;
		}
	};

	const onConfirmDelete = async () => {
		if (resource !== AzureResources.Database) {
			const result = await deleteQuery()!({ companyDomainId });

			if ("error" in result) {
				showErrorNotification({
					message: t("Something failed..."),
				});
			} else {
				showSuccessNotification({
					message: t("Resource has been successfully deleted"),
				});
			}

			refetch();

			setShowDeleteResourceModal(false);
		}
	};

	const label = `Delete ${resource.toLowerCase()}`;

	return (
		<>
			<Button
				onClick={() => setShowDeleteResourceModal(true)}
				icon={<IconTrash />}
				disabled={disabled}
				destructive
			>
				{label}
			</Button>
			<ConfirmDialog
				destructive
				open={showDeleteResourceModal}
				onClose={() => setShowDeleteResourceModal(false)}
				onConfirm={onConfirmDelete}
				isLoading={
					isDeletingStorageAccount || isDeletingDataFactory || isDisablingBackup
				}
				title={label}
				confirmButtonLabel={label}
				description={t("Are you sure you want to delete this resource?")}
			/>
		</>
	);
};
