import { LoadingState } from "../../common/components/atoms/loadingState/LoadingState";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { PersistedLink } from "../../common/persistentRouterFunctions";
import { useDocumentTitle } from "../../common/utils/hooks/useDocumentTitle";
import { useGetAllCompaniesQuery } from "../../common/redux/api/exopenApi";
import { AddCompany } from "./AddCompany";
import { IconPlus } from "@tabler/icons-react";
import { Anchor } from "../../common/components/atoms/anchor/Anchor";
import { MissingDataState } from "../../common/components/atoms/missingDataState/MissingDataState";
import { dateStringSort, stringSort } from "../../common/utils/hooks/useSort";
import { useLocale } from "src/locales/useLocale";
import { Button } from "../../common/components/atoms/button/Button.tsx";
import { PlanningTableContainer } from "src/features/planning/components/PlanningTable.tsx";
import { BreadcrumbsLayout } from "src/common/components/layout/BreadcrumbsLayout.tsx";
import { Input } from "components/input/Input.tsx";
import { FilterNoResults } from "src/features/planning/components/FilterNoResults.tsx";
import { ItemPicker } from "src/common/components/item-picker/ItemPicker.tsx";

export const Companies = () => {
	const { t } = useTranslation();

	const { isLoading: isLoadingCompanies, data: companies } =
		useGetAllCompaniesQuery();

	const [searchTerm, setSearchTerm] = useState("");
	const { formatRelative } = useLocale();

	const [type, setType] = useState<string | null>(null);
	const [sort, setSort] = useState<"newest" | "oldest" | "a-z" | "z-a" | null>(
		null,
	);

	const filteredCompanyDomains = useMemo(() => {
		return (companies ?? []).filter(
			(company) =>
				company.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
				(!type ? true : company.type === type),
		);
	}, [companies, searchTerm, type]);

	const pageName = t("Company domains");
	useDocumentTitle(pageName);

	const sortedCompanyDomains = useMemo(() => {
		switch (sort) {
			case "a-z":
			case null:
				return filteredCompanyDomains.toSorted((a, b) =>
					stringSort(a.name, b.name),
				);
			case "z-a":
				return filteredCompanyDomains.toSorted((a, b) =>
					stringSort(b.name, a.name),
				);
			case "newest":
				return filteredCompanyDomains.toSorted((a, b) => {
					return dateStringSort(b.dateCreated!, a.dateCreated!);
				});
			case "oldest":
				return filteredCompanyDomains.toSorted((a, b) => {
					return dateStringSort(a.dateCreated!, b.dateCreated!);
				});
		}
	}, [filteredCompanyDomains, sort]);

	if (isLoadingCompanies) {
		return <LoadingState />;
	}

	if (!companies) {
		return <MissingDataState />;
	}

	const companyDomainTypes = companies.reduce((set, companyDomain) => {
		set.add(companyDomain.type);
		return set;
	}, new Set<string>());

	return (
		<BreadcrumbsLayout breadcrumbs={[{ name: t("Company domains") }]}>
			<div className="mb-4 flex gap-4">
				<Input
					containerProps={{ className: "grow" }}
					placeholder={t("Filter by name")}
					type="search"
					value={searchTerm}
					onChange={(event) => {
						setSearchTerm(event.target.value);
					}}
				/>

				<AddCompany>
					<Button icon={<IconPlus />}>{t("New Company Domain")}</Button>
				</AddCompany>
			</div>
			<PlanningTableContainer>
				<div className="flex border-b p-2 gap-1 items-center">
					<div className="grow" />
					<ItemPicker
						items={Array.from(companyDomainTypes).map((type) => {
							return { id: type, label: type };
						})}
						onSelect={setType}
						selected={type}
						filterHeaderLabel={t("Filter by type")}
						filterLabel={t("Type")}
					/>
					<ItemPicker
						items={[
							{ id: "newest" as const, label: t("Newest") },
							{ id: "oldest" as const, label: t("Oldest") },
							{ id: "a-z" as const, label: t("Name A - Z") },
							{ id: "z-a" as const, label: t("Name Z - A") },
						]}
						defaultValue="a-z"
						onSelect={setSort}
						selected={sort}
						filterHeaderLabel={t("Sort by")}
						filterLabel={t("Sort")}
					/>
				</div>

				{sortedCompanyDomains.length === 0 ? (
					<FilterNoResults
						onClearFilter={() => {
							setSearchTerm("");
							setType(null);
						}}
					/>
				) : (
					<div className="bg-white">
						{sortedCompanyDomains.map((company) => {
							return (
								<div
									key={company.id}
									className="px-3 py-2 hover:bg-gray-50 border-b last:border-b-0"
								>
									<Anchor
										component={PersistedLink}
										to={`/${company.id}`}
										className="mb-1"
									>
										{company.name}
									</Anchor>
									<div className="text-gray-500 text-xs flex gap-1">
										<span>#{company.id}</span>{" "}
										<span>
											· {formatRelative(new Date(company.dateCreated!))}
										</span>
									</div>
								</div>
							);
						})}
					</div>
				)}
			</PlanningTableContainer>
		</BreadcrumbsLayout>
	);
};
