export interface SieAccount {
	code: number;
	name: string;
	amount: number;
	isOkay: boolean;
}

export type SieAccountGroup = {
	name: string;
	accountFrom: number;
	accountTo: number;
	amount: number;
} & (
	| {
			isSummationEntry: true;
			accounts: null;
			allAccountsOk: null;
	  }
	| {
			isSummationEntry: false;
			accounts: SieAccount[];
			allAccountsOk: boolean;
	  }
);

export interface SieAccountPlan {
	accountGroups: SieAccountGroup[];
}

interface SieNotOkAccount {
	number: number;
	description: string;
}

interface SiePeriod {
	fiscalYearEntityId: string;
	toDate: string;
	fromDate: string;
}

export interface SieValidateResponse {
	companyName: string;
	organizationNumber: string;
	sieId: string;
	isValid: boolean;
	parserError: { message: unknown } | null;
	parserWarnings: [];
	accountResultReport: {
		allAccountResultsAreOk: boolean;
		notOkayAccounts: SieNotOkAccount[];
		accountPlanResult: SieAccountPlan;
	};
	accountBalanceReport: {
		allAccountResultsAreOk: boolean;
		notOkayAccounts: SieNotOkAccount[];
		accountPlanBalance: SieAccountPlan;
	};
	overlappingPeriods: SiePeriod[];
}

export interface DataLayerUrlsResponse {
	dataLayerFileSystemUrl: string;
	dataLayerPathUrl: string;
}

export interface CompanyMart {
	martId: number;
	name: string;
	enabled: boolean;
	version: number;
}

export interface PbiAppInstallation {
	url: string;
	ticket: string;
}

export interface ApiLegalEntity {
	id: number;
	name: string;
	leMasterKey: string;
}

export interface DlmLegalEntityMartConfig {
	legalEntityId: string;
	financeMartEnabled: boolean;
}

export interface DlmRawMartConfig {
	integrationId: number;
	rawMartEnabled: boolean;
}

export interface DlmCompanyConfig {
	companyDomainId: string;
	dbConnectionDetails: {
		dbConnectionString: string;
	};
	storageAccountDetails: {
		storageAccountName: string;
		accountKey: string;
	};
	legalEntityMarts: DlmLegalEntityMartConfig[];
	financeMartEnabled: boolean;
	rawDbSchemaPrefix: string;
	rawIntegrations: DlmRawMartConfig[];
	rawMartEnabled: boolean;
	dbSchema: string;
}

export interface DlmDataObjectTypes {
	type: string;
	mart: string;
}

export interface TemplateApp {
	appId: string;
	packageKey: string;
	ownerTenantId: string;
	name: string;
}

export interface DatabaseUser {
	username: string;
	password: string;
}

export interface DatabaseConnection {
	hostname: string;
	databaseName: string;
}

export enum ConfigurationStatus {
	NotConfigured = "NotConfigured",
	Success = "Success",
	Failed = "Failed",
}

export interface CompanyStatusInfo {
	company: {
		id: number;
		name: string;
		pumpDatabaseInstance: string;
		databaseName: string;
		dateCreated: string;
		type: string;
		initStatus: ConfigurationStatus;
		databaseStatus: ConfigurationStatus;
		storageAccountStatus: ConfigurationStatus;
		dataFactoryStatus: ConfigurationStatus;
		databaseBackupStatus: ConfigurationStatus;
	};
	ewr: {
		url: string;
		status: string;
	} | null;
	pumps: {
		id: number;
		name: string;
		companyId: number;
		dateCreated: string;
		pumpType: string;
	}[];
	hasDataLayer: boolean;
	ewrPackages: {
		package: {
			numReports: number;
			name: string;
			id: string;
			isPublicPackage: boolean;
			isInternalPackage: boolean;
			reports: {
				id: number;
				displayName: string;
				description: string;
				requirements: string;
				folder: string;
			}[];
		};
		deployed: boolean;
	}[];
	marts: {
		id: number;
		name: string;
		configs: {
			id: number;
			martVersion: number;
			name: string;
		};
	}[];
	leMasterKeyMappings: {
		id: number;
		pumpId: number;
		leMasterKey: string;
		companyName: string;
	}[];
}
