import { IconDatabase } from "@tabler/icons-react";
import { Button } from "components/button";
import {
	showErrorNotification,
	showSuccessNotification,
} from "components/notifications/events";
import { useTranslation } from "react-i18next";
import {
	useEnableCompanyDomainDatabaseBackupMutation,
	useProvisionCompanyDomainDatabaseMutation,
	useProvisionCompanyDomainDataFactoryMutation,
	useProvisionCompanyDomainStorageAccountMutation,
} from "src/common/redux/api/exopenApi";
import { AzureResources } from "./azureResources";

interface Props {
	resource: AzureResources;
	companyDomainId: string;
	disabled: boolean;
	refetch: () => void;
}

export const ProvisionResourceButton = ({
	resource,
	companyDomainId,
	disabled,
	refetch,
}: Props) => {
	const { t } = useTranslation();

	const [
		provisionCompanyDomainDatabase,
		{ isLoading: isProvisioningDatabase },
	] = useProvisionCompanyDomainDatabaseMutation();

	const [
		provisionCompanyDomainStorageAccount,
		{ isLoading: isProvisioningStorageAccount },
	] = useProvisionCompanyDomainStorageAccountMutation();

	const [
		provisionCompanyDomainDataFactory,
		{ isLoading: isProvisioningDataFactory },
	] = useProvisionCompanyDomainDataFactoryMutation();

	const [enableCompanyDomainDatabaseBackup, { isLoading: isEnablingBackup }] =
		useEnableCompanyDomainDatabaseBackupMutation();

	const provisionQuery = () => {
		switch (resource) {
			case AzureResources.Database:
				return provisionCompanyDomainDatabase;
			case AzureResources.DataFactory:
				return provisionCompanyDomainDataFactory;
			case AzureResources.StorageAccount:
				return provisionCompanyDomainStorageAccount;
			case AzureResources.DatabaseBackup:
				return enableCompanyDomainDatabaseBackup;
		}
	};

	const label = `Create ${resource.toLowerCase()}`;

	return (
		<Button
			ariaLabel={label}
			icon={<IconDatabase />}
			onClick={async () => {
				const result = await provisionQuery()({ companyDomainId });

				if ("error" in result) {
					showErrorNotification({
						message: t("Something failed..."),
					});
				} else {
					showSuccessNotification({
						message: t("Resource has been successfully provisioned"),
					});
				}

				refetch();
			}}
			disabled={disabled}
			isLoading={
				isProvisioningDatabase ||
				isProvisioningStorageAccount ||
				isProvisioningDataFactory ||
				isEnablingBackup
			}
		>
			{label}
		</Button>
	);
};
