import { type Builder, TAGS } from "../../types.js";
import type {
	LegalEntity,
	LegalEntityHierarchyResponse,
	UpdateHierarchyRequestBody,
} from "./types.js";

export const buildCompanyDomainEndpoints = (builder: Builder) => ({
	getLegalEntities: builder.query<LegalEntity[], string>({
		query: (companyDomainId) =>
			`/v1/company-domain/${companyDomainId}/legal-entity`,
		providesTags: (result) => {
			if (result) {
				return [
					"LegalEntity",
					...result.map((legalEntity) => ({
						type: "LegalEntity" as const,
						id: legalEntity.id,
					})),
				];
			}
			return ["LegalEntity"];
		},
	}),

	getLegalEntity: builder.query<
		LegalEntity,
		{ companyDomainId: string; legalEntityId: string }
	>({
		query: ({ companyDomainId, legalEntityId }) =>
			`/v1/company-domain/${companyDomainId}/legal-entity/${legalEntityId}`,
		providesTags: (_result, error, arg) => {
			if (error) {
				return ["LegalEntity"];
			}
			return [
				"LegalEntity",
				{
					type: "LegalEntity",
					id: arg.legalEntityId,
				},
			];
		},
	}),

	upsertLegalEntityFromFusionhubIntegrations: builder.mutation<
		LegalEntity,
		{ companyDomainId: string; integrationIds: number[] }
	>({
		query: ({ companyDomainId, integrationIds }) => ({
			url: `/v1/company-domain/${companyDomainId}/legal-entity/integration/fusionhub/`,
			method: "PUT",
			body: {
				fusionHubIntegrationIds: integrationIds,
			},
		}),
		invalidatesTags: (_, err) => {
			if (err) {
				return [];
			}

			return TAGS;
		},
	}),

	deleteLegalEntity: builder.mutation<
		void,
		{ companyDomainId: string; legalEntityId: string }
	>({
		query: ({ companyDomainId, legalEntityId }) => ({
			url: `/v1/company-domain/${companyDomainId}/legal-entity/${legalEntityId}`,
			method: "DELETE",
		}),
		invalidatesTags: () => TAGS,
	}),

	getCompanyHierarchy: builder.query<LegalEntityHierarchyResponse, string>({
		query: (companyDomainId) =>
			`/v1/company-domain/${companyDomainId}/hierarchy`,
		providesTags: () => ["LegalEntityStructure"],
	}),

	updateCompanyHierarchy: builder.mutation<
		LegalEntityHierarchyResponse,
		{ companyDomainId: string; hierarchy: UpdateHierarchyRequestBody }
	>({
		query: ({ companyDomainId, hierarchy }) => ({
			url: `/v1/company-domain/${companyDomainId}/hierarchy`,
			method: "PUT",
			body: hierarchy,
		}),
		invalidatesTags: () => TAGS,
	}),

	removeCompanyHierarchy: builder.mutation<
		LegalEntityHierarchyResponse,
		{ companyDomainId: string }
	>({
		query: ({ companyDomainId }) => ({
			url: `/v1/company-domain/${companyDomainId}/hierarchy`,
			method: "DELETE",
		}),
		invalidatesTags: () => TAGS,
	}),

	getCompanyDomains: builder.query<any, any>({
		query() {
			return "/v1/company-domain";
		},
	}),
});
