import clsx from "clsx";
import type { ComponentProps } from "react";

export const GridColumn = ({ className, ...props }: ComponentProps<"div">) => {
	return (
		<div
			className={clsx(
				"border-b px-3 py-2 group-last:border-b-0 whitespace-nowrap flex items-center group-hover:bg-gray-50",
				className,
			)}
			{...props}
		/>
	);
};
